body,
html {
    margin: 0;
    padding: 0;
    font-family: 'Raleway', sans-serif !important;
    scroll-behavior: smooth;
    height: 100%;
    width: 100%;
}

#root {
    height: 100%;
    width: 100%;
}

button {
    font-family: 'Raleway', sans-serif !important;
}

textarea {
    font-family: 'Raleway', sans-serif !important;
}

input {
    font-family: 'Raleway', sans-serif !important;
}

* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*:focus {
    outline: none;
}

@media only screen and (min-width: 768px) {
    *.mobile-only {
        display: none !important;
    }
}

@media only screen and (max-width: 768px) {
    *.hide-mobile {
        display: none !important;
    }
}